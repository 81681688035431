import { gql } from "@apollo/client";

export const productFields = gql`
    fragment productFields on ProductInterface {
        __typename
        categories {
            id
            name
            level
            url_key
            breadcrumbs {
                category_level
                category_name
                category_url_key
            }
        }
        short_description {
            html
        }
        description {
            html
        }
        id
        ont_image_url_1
        media_gallery_entries {
            id
            label
            position
            disabled
            file
        }
        meta_title
        meta_keyword
        meta_description
        name
        price {
            regularPrice {
                amount {
                    currency
                    value
                }
            }
        }
        sku
        small_image {
            url
        }
        url_key
        ont_mask_sku
        ont_product_overview
        ont_workflow
        ont_apps_and_pubs
        ont_safety_and_legal
        ont_whats_in_the_box
        ont_multiplex_detail
        ont_party_materials
        ont_compatibility
        ont_render_type
        ont_image_url_1
    }

    fragment bundleProductFields on BundleProduct {
        price_range {
            minimum_price {
                final_price {
                    currency
                    value
                }
            }
            maximum_price {
                final_price {
                    currency
                    value
                }
            }
        }
        items {
            option_id
            title
            type
            options {
                id
                is_default
                quantity
                uid
                can_change_quantity
                product {
                    sku
                    name
                    short_description {
                        html
                    }
                    description {
                        html
                    }
                    ont_mask_sku
                    ont_prep_minutes
                    ont_concentration
                    ont_throughput
                    ont_image_url_1
                    price {
                        regularPrice {
                            amount {
                                value
                            }
                        }
                    }
                }
            }
            position
            required
            sku
            title
            type
        }
    }

    fragment configurableProductFields on ConfigurableProduct {
        price_range {
            maximum_price {
                final_price {
                    currency
                    value
                }
            }
            minimum_price {
                final_price {
                    currency
                    value
                }
            }
        }
        configurable_options {
            attribute_code
            attribute_id
            id
            label
            values {
                default_label
                label
                store_label
                use_default_value
                value_index
                swatch_data {
                    ... on ImageSwatchData {
                        thumbnail
                    }
                    value
                }
            }
        }
        variants {
            attributes {
                code
                uid
                value_index
            }
            product {
                id
                ont_render_type
                media_gallery_entries {
                    id
                    disabled
                    file
                    label
                    position
                }
                sku
                stock_status
                price {
                    regularPrice {
                        amount {
                            currency
                            value
                        }
                    }
                }
                websites {
                    name
                    code
                }
            }
        }
    }

    fragment groupedProductFields on GroupedProduct {
        items {
            qty
            position
            product {
                sku
                name
                url_key
                short_description {
                    html
                }
                price {
                    regularPrice {
                        amount {
                            currency
                            value
                        }
                    }
                }
                ont_image_url_1
            }
        }
    }
`
