import React from "react"
import LiveProduct from "../components/Live/LiveProduct"

const LiveProductPage = ({params}) => {
    return (
        <LiveProduct urlKey={params["*"]} />
    );
}

export default LiveProductPage
