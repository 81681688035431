import React from 'react';
import { navigate } from 'gatsby';
import {gql, useLazyQuery} from "@apollo/client";
import {isBrowser} from "../../../utils/useLocalStorage";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import getProductByUrlKey from "../../../utils/product";
import {Helmet} from "react-helmet";
import Product from "../../Product";
import {productFields} from "../../../fragments/live/product";

export default function LiveProduct({urlKey}) {
    if (! isBrowser()) {
        return null;
    }
    const [makeProductsQuery, productsResult] = useLazyQuery(
        productsQuery,
        {
            variables: {
                urlKey: urlKey
            },
            fetchPolicy: 'network-only',
        }
    );

    if (! productsResult.called) {
        makeProductsQuery();
    }

    if (productsResult.loading) {
        return (
            <center><br/><FontAwesomeIcon icon={faSpinner} size="lg" spin /></center>
        );
    }

    if (productsResult.called) {
        if (productsResult.data) {
            const product = getProductByUrlKey(urlKey, productsResult.data.productDetail.items);
            if (product) {
                return (
                    <>
                        <Helmet>
                            <title>{product.name}</title>
                        </Helmet>
                        <Product
                            product={product}
                            urlKey={urlKey}
                            siblingProducts={[]}
                        />
                    </>
                );
            }
        }
        navigate('/404');
    }

    return null;
}

const productsQuery = gql`
    query productDetail($urlKey: String) {
        productDetail: products(filter: { url_key: { eq: $urlKey } }) {
            items {
                ...productFields
                ...bundleProductFields
                ...configurableProductFields
                ...groupedProductFields
            }
        }
    }

    ${productFields}
`;

const siblingsQuery = gql`
    query productDetail($urlKey: String, $categorySiblingUrlKeys: [String]) {
        siblingProductsDetail: products(filter: { url_key: { in: $categorySiblingUrlKeys } }) {
            items {
                ...productFields
                ...bundleProductFields
                ...configurableProductFields
                ...groupedProductFields
            }
        }
    }

    ${productFields}
`;
